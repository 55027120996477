
  import { Component } from 'vue-property-decorator'

  import { ExpandableAuto, Panels } from '../expandable'

  import { Deal } from '@/entities/crm'

@Component({
  components: {
    ExpandableAuto,
    ...Panels,
    Row: () => import('../simple.vue'),
  },
})
  export default class ExpandableAppraisal extends ExpandableAuto {
    get deal () {
      return (this.value as unknown) as Deal
    }

    get appraisal () {
      const { deal: { appraisal } } = this
      return appraisal
    }

    get closingReason () {
      const { appraisal: { closingReason } } = this
      return closingReason
    }

    get autoAppraisal () {
      const { bind, queries, deal, closingReason, detailed } = this
      const value = deal.auto
      const { status } = deal.appraisal
      let chip
      if (!closingReason) {
        chip = {
          color: `${status?.color} lighten-1`,
          value: status?.description,
          textColor: status?.color,
          small: true,
        }
      } else {
        chip = {
          color: `${closingReason.color} lighten-1`,
          value: closingReason.description,
          textColor: closingReason.color,
          small: true,
        }
      }

      return { ...bind, queries, value, chip, detailed: detailed ?? false, displayPhoto: false }
    }

    get market () {
      const { appraisal: { externalOffers } } = this
      if (!externalOffers?.length) return undefined

      return {
        items: externalOffers,
        itemText: 'value',
        itemLabel: 'name',
        formatter: 'fixPrice',
      }
    }

    get maintenance () {
      const { deal: { auto: { maintenances } } } = this
      if (!maintenances?.length) return undefined

      const config = { color: 'blue lighten-2', textColor: 'blue darken-1', small: true }

      const filteredMaintenance = maintenances.filter(maintenance => maintenance?.status?.isDone)

      return {
        chips: filteredMaintenance.length ? filteredMaintenance : maintenances,
        config,
        itemText: 'description',
        last: {
          ...config,
          value: filteredMaintenance.length ? filteredMaintenance[0]?.description : maintenances[0].description,
        },
      }
    }

    get attachments () {
      return undefined
    }

    get pictures () {
      return undefined
    }

    get favorites () {
      const { deal: { autoAttributes: attributes } } = this
      if (!attributes) return []
      let favorites = attributes?.filter(({ base: { favorite } }) => favorite) || []

      const mileages = favorites?.filter(({ base: { slug } }) => slug === 'mileage') || []
      const owners = favorites?.filter(({ base: { slug } }) => slug === 'owners_number') || []

      favorites = attributes?.filter(({
        base: {
          slug,
          favorite,
        },
      }) => slug !== 'mileage' && slug !== 'owners_number' && favorite) || []
      if (mileages?.length) {
        favorites.push(mileages[mileages.length - 1])
      }
      if (owners?.length) {
        favorites.push(owners[owners.length - 1])
      }

      return favorites
    }
  }
